<template>
  <!-- Google Tag Manager (noscript) -->
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WT7L3D8" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->

  <div class="page" :class="[`page--${currentPage.classPage}`]">
    <NuxtLoadingIndicator color="#348feb" height="5" throttle="0"  />
    <Header />
		<slot />
		<Footer />
	</div>
</template>

<script setup>
useHead(() => ({
  script: [
    {
      src: 'https://cdn-cookieyes.com/client_data/e6d14ba9f9b4c0fd6ffcb280/script.js',
      'id': 'cookieyes',
    },
    {
      children: "<!-- Google Tag Manager -->\n" +
          "    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
          "      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
          "      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
          "      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
          "    })(window,document,'script','dataLayer','GTM-WT7L3D8');" +
          "<!-- End Google Tag Manager -->",
    }
  ]
}));
</script>

<script>
	import sitemapMixin from '~/mixins/sitemap-mixin'
	import Header from '~/components/common/Header';
	import Footer from '~/components/common/Footer';

	export default {
		mixins: [sitemapMixin],
		components: {
			Header,
			Footer
		},
		computed: {
			currentPage() {
				return this.sitemap.find((item) => {
					return this.$route.name.indexOf(item.slug) !== -1;
				});
			}
		},
	}
</script>