<template>
	<header class="header">
		<div class="header__left">
			<nuxtLink to="/">
				<img v-if="isCategoryPage" src="~/assets/images/logo-white.svg" alt="Liga za duševné zdravie"  class="header__logo"/>
				<img v-else src="~/assets/images/logo.svg" alt="Liga za duševné zdravie"  class="header__logo"/>
			</nuxtLink>

      <ul class="header__nav" :class="{'is-active' : isMenuActive, 'header__nav-hidden': isSearchPage}">
		<div class="header__button-helpline">
      <nuxtLink to="{{headerMenuItem.url}}" class="button button--primary" tag="a" target="_blank" v-for="headerMenuItem in headerMenuData" v-html="headerMenuItem.post_title" />
		</div>

        <li class="header__nav-dropdown" v-for="menuItem in menuData ">

          <nuxtLink tag="a" :to="menuItem.child_items ? '' : menuItem.url" class="header__nav-item" @click.prevent="closeMenu()" @click="toggleSubmenuAlt('o-nas-menu')">{{ menuItem.post_title }}</nuxtLink>

          <!-- submenu -->
          <ul class="header__nav-submenu" id="o-nas-menu" v-if="menuItem.child_items">
            <li v-for="childItem in menuItem.child_items">
              <a :href="childItem.url">{{ childItem.post_title }}</a>
            </li>
          </ul>

        </li>

<!--        <li class="header__nav-dropdown">-->
<!--          <nuxt-link-->
<!--              to=""-->
<!--              tag="a"-->
<!--              class="header__nav-item"-->
<!--              :class="{'is-active': subIsActive(['/o-nas'])}"-->
<!--              id="o-nas"-->
<!--              @click="toggleSubmenuAlt('o-nas-menu')"-->
<!--          >-->
<!--            O nás-->
<!--          </nuxt-link>-->
<!--          <ul class="header__nav-submenu" id="o-nas-menu">-->
<!--            <li>-->
<!--              <nuxt-link to="/o-nas/kto-sme" tag="a" @click.prevent="closeMenu()">Kto sme</nuxt-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxt-link to="/o-nas/eticke-kodexy" tag="a" @click.prevent="closeMenu()">Etické kódexy</nuxt-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxt-link to="/o-nas/partneri-a-donori" tag="a" @click.prevent="closeMenu()">Partneri a donori-->
<!--              </nuxt-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxt-link to="/o-nas/clenovia-ligy" tag="a" @click.prevent="closeMenu()">Členovia Ligy za duševné-->
<!--                zdravie-->
<!--              </nuxt-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxt-link to="/o-nas/stanoviska-ligy" tag="a" @click.prevent="closeMenu()">Stanoviská ligy</nuxt-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxt-link to="/o-nas/vyrocne-spravy" tag="a" @click.prevent="closeMenu()">Výročné správy</nuxt-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxt-link to="/o-nas/pre-media" tag="a" @click.prevent="closeMenu()">Pre média</nuxt-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxt-link to="/o-nas/kontakty" tag="a" @click.prevent="closeMenu()">Kontakty</nuxt-link>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        <li>-->
<!--          <nuxtLink to="" tag="a" class="header__nav-item" @click.prevent="closeMenu()">Mapa podpory</nuxtLink>-->
<!--        </li>-->
<!--        <li class="header__nav-dropdown">-->
<!--          <nuxt-link-->
<!--              to=""-->
<!--              tag="a"-->
<!--              class="header__nav-item"-->
<!--              :class="{'is-active': subIsActive(['/kampane','/podcast','/vsetky-vysledky/diskusie','/publikacie','/zbierka-dni-nezabudiek','/ocenenie-dobre-kruhy','/testy'])}"-->
<!--              id="co-pre-vas-robime"-->
<!--              @click="toggleSubmenuAlt('co-pre-vas-robime-menu')"-->
<!--          >-->
<!--            Čo pre Vás robíme-->
<!--          </nuxt-link>-->
<!--          <ul class="header__nav-submenu" id="co-pre-vas-robime-menu">-->
<!--            <li>-->
<!--              <nuxtLink to="/kampane" tag="a" @click.prevent="closeMenu()">Osvetové kampane</nuxtLink>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxtLink to="/podcast" tag="a" @click.prevent="closeMenu()">Podcast</nuxtLink>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxtLink to="/vsetky-vysledky/diskusie" tag="a" @click.prevent="closeMenu()">Verejné diskusie</nuxtLink>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxtLink to="/publikacie" tag="a" @click.prevent="closeMenu()">Publikácie</nuxtLink>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxtLink to="/zbierka-dni-nezabudiek" tag="a" @click.prevent="closeMenu()">Zbierka Dni Nezábudiek-->
<!--              </nuxtLink>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxtLink to="/ocenenie-dobre-kruhy" tag="a" @click.prevent="closeMenu()">Ocenenie Dobré kruhy</nuxtLink>-->
<!--            </li>-->
<!--            <li>-->
<!--              <nuxtLink to="/testy" tag="a" @click.prevent="closeMenu()">Testy</nuxtLink>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        <li>-->
<!--          <nuxtLink to="/vsetky-vysledky/ligovinky" tag="a" class="header__nav-item" @click.prevent="closeMenu()">Ligovinky</nuxtLink>-->
<!--        </li>-->
<!--        <li>-->
<!--          <nuxtLink to="/peerovia" tag="a" class="header__nav-item" @click.prevent="closeMenu()">Naši peerovia</nuxtLink>-->
<!--        </li>-->
      </ul>

			<div class="header__search" :class="{'header__search-active': isSearchPage}">
				<input type="text" class="input header__search-input" placeholder="| Zadajte hľadaný výraz" v-on:keyup.enter="search"/>
				<img src="~/assets/images/icon/close.svg" alt="" class="header__search-input__icon" @click="cancelSearch()"/>
				<button class="button header__search-button" @click="search()">Hľadať</button>
			</div>
		</div>
		<div class="header__right">
			<button type="button" class="button header__button-search" :class="{'header__button-search-hidden': isSearchPage}" @click="openSearch()"></button>
			<button
				type="button"
				class="header__menu-button"
				:class="{'is-active' : isMenuActive}"
				@click.prevent="toggleMenu"
			>
				<span></span>
				<span></span>
				<span></span>
			</button>
			<div class="header__button-helpline">
        <nuxtLink to="{{headerMenuItem.url}}" class="button button--primary" tag="a" target="_blank" v-for="headerMenuItem in headerMenuData" v-html="headerMenuItem.post_title" />
			</div>
		</div>
	</header>
</template>

<script>
	import {isMobile} from "~/composables/utils";

  export default {
		name: 'Header',
		data() {
			return {
				isMenuActive: false,
        menuData: [],
        headerMenuData: [],
			}
		},
		async mounted() {
      this.body = document.querySelector('body');
      this.header = document.querySelector('header');
      this.mainLogo = this.header.querySelector('.header__logo');
      this.headerNav = this.header.querySelector('.header__nav');
      this.headerSearch = this.header.querySelector('.header__search');
      this.headerSearchInput = this.header.querySelector('.header__search-input');
      this.headerSearchButton = this.header.querySelector('.header__search-button');
      this.headerNavSearchButton = this.header.querySelector('.header__button-search');
      this.headerNavButton = this.header.querySelector('.header__menu-button');
      // this.oNas = document.getElementById('o-nas');
      // this.oNasMenu = document.getElementById('o-nas-menu');
      // this.coPreVasRobime = document.getElementById('co-pre-vas-robime');
      // this.coPreVasRobimeMenu = document.getElementById('co-pre-vas-robime-menu');
      this.submenu = document.getElementsByClassName('header__nav-submenu');
      for (var i = 0; i < this.submenu.length; i++) {
        this.submenu[i].style.display = 'none';
      }

      if (this.$route.params.searchText && this.$route.params.searchText.length > 0) {
        this.header.querySelector('.header__search-input').value = this.$route.params.searchText;
      }
      try {
        this.menuData = await fetchWP('/backend/wp-json/menus/v1/menus/horne-menu');
        this.menuData = this.menuData?.items;
      } catch (e) {
        this.menuData = [];
      }

      try {
        this.headerMenuData = (await fetchWP('/backend/wp-json/menus/v1/menus/header-menu'))?.items ?? [];
      } catch (e) {
        this.headerMenuData = [];
      }

    },
		computed: {
			isCategoryPage() {
				return this.$route.name === 'category';
			},
			isSearchPage() {
				return this.$route.name === 'vyhladavanie-searchText';
			},
		},
		methods: {
			subIsActive(input) {
				const paths = Array.isArray(input) ? input : [input]
				return paths.some(path => {
					return this.$route.path.indexOf(path) === 0 // current path starts with this path string
				})
			},
			openMenu() {
				if (process.client) {
					this.body.style.overflow = 'hidden';
					this.body.style.position = 'relative';

					this.headerHeight = this.header.offsetHeight;
					this.headerNav.style.top = this.headerHeight + 'px';
				}

				if (this.isMenuActive) {
					return;
				}

				this.isMenuActive = true;
			},
			openSearch() {
        this.mainLogo.style.display = 'none';
				this.headerNavSearchButton.style.display = 'none';
				this.headerNav.style.display = 'none';
        this.headerNavButton.style.display = 'none';
				this.headerSearch.style.display = 'flex';
        this.headerSearchInput.focus();
      },
			hideMenu() {
				if (!this.isMenuActive) {
					return;
				}

				if (process.client) {
					this.body.style.overflow = '';
					this.body.style.position = '';
					this.headerNav.style.top = '';
				}

				this.isMenuActive = false;
				this.submenu.solutions = false;
				this.submenu.audiences = false;
			},
			toggleMenu() {
				return this.isMenuActive ? this.hideMenu() : this.openMenu();
			},
			toggleSubmenu(option) {
				for(let i in this.submenu) {
					this.submenu[i] = i === option;
				}
			},
			toggleSubmenuAlt(submenuId) {
				for(var i = 0; i < this.submenu.length; i++) {
					if (this.submenu[i].id === submenuId) {
						this.submenu[i].style.display = this.submenu[i].style.display == 'none' || '' ? 'flex' : 'none';
					} else {
						this.submenu[i].style.display = 'none';
					}

				}
			},
			closeMenu() {
				if (this.headerNavButton.classList.contains('is-active')) {
					this.toggleMenu();
				}
			},
			start(el) {
				el.style.height = el.scrollHeight + 'px';
			},
			end(el) {
				el.style.height = '';
			},
			search() {
				if (this.header.querySelector('.header__search-input').value.length > 0) {
					this.$router.push(`/vyhladavanie/${this.header.querySelector('.header__search-input').value}`);
				}
			},
			cancelSearch() {
        this.mainLogo.style.display = 'inline';
				this.headerNavSearchButton.style.display = 'flex';
				this.headerNav.style.display = 'flex';
				this.headerSearch.style.display = 'none';
        if (isMobile()) {
          this.headerNavButton.style.display = 'block';
        }
			},
		},
	}
</script>

<style lang="scss">
	.submenu-enter-active,
	.submenu-leave-active {
		will-change: height, opacity;
		transition: height 0.2s linear, opacity 0.2s linear;
		overflow: hidden;
	}

	.submenu-enter,
	.submenu-leave-to {
		height: 0 !important;
		opacity: 0;
	}
</style>
